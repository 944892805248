import React from 'react';
import { Menu, Icon, Breadcrumb, Input, Label, Checkbox, Button, Tab } from 'semantic-ui-react'
import './page-css.css'
import Helper from '../../../utils/helper'
import MyPage from './page-componenets/provider-coponents/my-page'
import UpdateAccount from './page-componenets/provider-coponents/update-account';
import UsersList from './page-componenets/provider-coponents/users-list';
import WishList from './page-componenets/user-components/wish-list';
import UpdateUserAccount from './page-componenets/user-components/update-user-account';
import CustomServices from './page-componenets/user-components/custom-services-component/custom-services';



export default class PageTabs extends React.Component {

    constructor(props) {
        super(props);
        let userType = localStorage.getItem('user_type') ? localStorage.getItem('user_type') : 'provider'
        this.state = {
            userType: userType,
            lastSection: ''
        }

    }

    changeLastSection = (section) => {
        this.setState({
            lastSection: section
        })
    }

    componentDidMount() {

    }

    clicked = () => {
        window.location = window.location.origin
    };

    changePage = (page) => {
        this.props.history.push(`/${page ? page : ''}`)
    }
    render() {
        let { userType, lastSection } = this.state;
        let width = '12.82%'
        // userType === 'user' ? `${100 / 4}%` : `${100 / 3}%`;
        let panes = [];
        if (userType === 'user') {
            panes = [{
                menuItem: (
                    <Menu.Item key='basic' style={{ width: width, textAlign: "center" }}>
                        <h4 className={'tab-header'}>利用中サービス</h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane>
                    <CustomServices props={this.props} data={this.state.data} />
                </Tab.Pane>,
            },
            {
                menuItem: (
                    <Menu.Item key='email_template' style={{ width: width, textAlign: "center" }}>
                        <h4 className={'tab-header'}>マイページ</h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane>
                    {/* <Notifications props={this.props} data={this.state.data} /> */}
                </Tab.Pane>,
            },
            {
                menuItem: (
                    <Menu.Item key='email_template' style={{ width: width, textAlign: "center" }}>
                        <h4 className={'tab-header'}>アカウント情報</h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane>
                    <UpdateUserAccount props={this.props} data={this.state.data} />
                </Tab.Pane>,
            },
            {
                menuItem: (
                    <Menu.Item key='email_template' style={{ width: width, textAlign: "center" }}>
                        <h4 className={'tab-header'}>気になるリスト</h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane>
                    <WishList props={this.props} data={this.state.data} />
                </Tab.Pane>,
            }
            ];
        } else {
            panes = [{
                menuItem: (
                    <Menu.Item key='basic' style={{ width: width, textAlign: "center" }}>
                        <h4 className={'tab-header'}>マイページ
                        </h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane>
                    <MyPage props={this.props} changeLastSection={this.changeLastSection} />
                </Tab.Pane>,
            },
            {
                menuItem: (
                    <Menu.Item key='email_template' style={{ width: width, textAlign: "center" }}>
                        <h4 className={'tab-header'}>アカウント情報
                        </h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane>
                    <UpdateAccount props={this.props} changeLastSection={this.changeLastSection} />
                </Tab.Pane>,
            },
            {
                menuItem: (
                    <Menu.Item key='email_template' style={{ width: width, textAlign: "center" }}>
                        <h4 className={'tab-header'}>利用者一覧
                        </h4>
                    </Menu.Item>
                ),
                render: () => <Tab.Pane>
                    <UsersList props={this.props} data={this.state.data} />
                </Tab.Pane>,
            }
            ];
        }

        return (
            <div className={'page-container'}>
                <Breadcrumb>
                    <Breadcrumb.Section link onClick={() => this.clicked()}>Top</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right chevron' />
                    {
                        Helper.emptyString(lastSection) ?
                            <Breadcrumb.Section active>{`マイページ`}</Breadcrumb.Section>
                            :
                            <>
                                <Breadcrumb.Section>{`マイページ`}</Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron' />
                                <Breadcrumb.Section active>{`${lastSection}`}</Breadcrumb.Section>
                            </>
                    }

                </Breadcrumb>
                <div className='tabs-section'>
                    <div className='action-section'>
                        <Tab
                            className={'profile-tabs'}
                            menu={{ secondary: true, pointing: true }}
                            panes={panes} />
                    </div>
                </div>

            </div >
        );
    }


}