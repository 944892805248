import React from 'react';
import PageApi from "../../../api/page-api"
import { Breadcrumb, Header, Loader, Button, Search, Table } from 'semantic-ui-react'
import Helper from "../../../../../utils/helper"
import Card from "../../../../card-component/card"
import AddService from './add-service'

export default class UsersList extends React.Component {

    constructor(props) {
        super(props);
        this.PageApi = new PageApi(this);
        this.state = {
            loading: true,
            services: [],
            currentService: {},
            usersList: [ ]
        }
    }

    async componentDidMount() {
        let { services, currentService } = this.state;


        const myServicesResponse = await this.PageApi.getMyServices()
        this.setState({ loading: false });
        if (myServicesResponse.data) {
            services = myServicesResponse.data.services
            currentService = services[0]
        }
        this.setState({
            services: services,
            currentService: currentService
        })
    }


    clicked = () => {
        window.location = window.location.origin
    };

    render() {
        let { loading, services, currentService, userName, usersList } = this.state;
        return (
            <div className={'users-list'}>
                {
                    loading ?
                        <Loader active={loading} />
                        :
                        <>
                            <div className='left-side'>
                                <p>提供中のサービス</p>
                                {
                                    services ?
                                        services.map((service) => {
                                            return <a>{service.name}</a>
                                        })
                                        : null
                                }

                                <a>すべての利用者</a>

                            </div>
                            <div className='right-side'>
                                {
                                    currentService ?
                                        <>
                                            <div className='service-users'>
                                                <h2>{currentService.name}</h2>
                                                <div className='user-number'>
                                                    <h3>利用者数の合計</h3>
                                                    <h1>{`20人`}</h1>
                                                </div>

                                            </div>
                                            <div className='action-buttons'>
                                                <Button>表示中の利用者情報をダウンロード（CSV）</Button>
                                                <div className='search-section'>
                                                    <Search
                                                        input={{ icon: 'search', iconPosition: 'left' }}
                                                        placeholder='利用者をキーワードで検索'
                                                        onSearchChange={(e, data) => this.handleSearchChange(e, data)}
                                                        results={[]}
                                                        value={userName}
                                                    />
                                                </div>
                                            </div>
                                            <div className='list-section'>
                                                <Table fixed unstackable selectable >
                                                    <Table.Header fullWidth>
                                                        <Table.Row>
                                                            <Table.HeaderCell className={'sort-header'}
                                                               width={3}
                                                            >
                                                                利用開始日
                                                            </Table.HeaderCell>

                                                            <Table.HeaderCell className={'sort-header'}
                                                                width={3}
                                                            >
                                                                氏名
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell className={'sort-header'}
                                                                width={6}
                                                            >
                                                                メールアドレス
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell className={'sort-header'}
                                                                width={4}
                                                            >
                                                                電話番号
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {
                                                            usersList?
                                                            usersList.map((user) => {
                                                                return <Table.Row >
                                                                <Table.Cell>{user.createdDate? user.createdDate: ''}</Table.Cell>
                                                                <Table.Cell>{user.name? user.name: ''}</Table.Cell>
                                                                <Table.Cell>{user.email? user.email: ''}</Table.Cell>
                                                                <Table.Cell>{user.phoneNumber? user.phoneNumber: ''}</Table.Cell>
                                                            </Table.Row>
                                                            })
                                                            : null
                                                        }
                                                    </Table.Body>
                                                </Table>
                                            </div>

                                        </>
                                        : null
                                }

                            </div>
                        </>
                }

            </div>
        );
    }
}
