import React from 'react';
import "./card-css.css"
import ServiceApi from "../service-details-component/api/service-api"
import {Header} from 'semantic-ui-react'
import Helper from "../../utils/helper"

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        this.serviceApi = new ServiceApi(this);
        this.state = {
            service: props? props.service? props.service: {}: {}
        }
    }

    openServiceDetails =  () => {
        this.props.props.history.push(`/${this.props.service.id}`)
        window.location.reload(true)
    }

    addToWishtList = async () => {
        let { service } = this.state;
        const response = service.added_to_wishlist? await this.serviceApi.deleteFromWishList({id: service.id}):
        await this.serviceApi.addToWishList({service_id: service.id})
        if(response.data){
            service.added_to_wishlist = !service.added_to_wishlist
        }
        this.setState({
            service: service
        })
    }


    render() {
        let service = this.props.service;
        if(!Helper.emptyString(service)){
            return (
                <div className={'card'} 
                >
                    <img className={'service-image'} 
                     onClick={() => this.openServiceDetails()}
                    src={`https://bonzuttner.xsrv.jp/spm-back/storage/${service.image}`}/>
                    <div className={'actions'}>
                        <img className={'like-image'} onClick={() => this.addToWishtList()}
                                                            src={service.added_to_wishlist ? '/images/main-images/liked.svg' : '/images/main-images/like.svg'} />
                        <img className={'plus-image'} src={'/images/main-images/plus.svg'}/>
                    </div>
                    <div className={"description-section"}>
                        <Header as={'h4'}>
                            {service.name}
                        </Header>
                        <p>
                            {service.introduction}
                        </p>
                    </div>
                    <div className={"price-section"}>
                        <Header as={'h3'}>
                            {`¥${service.price}/月`}
                        </Header>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    }
}
